import { createContext, useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { bigCommerceSDK } from 'scripts/bcSdk';

const SessionContext = createContext({ context: '' })
const ContextStoreName = 'pulsarContext';

const SessionProvider = ({ children }) => {
    const [searchParams] = useSearchParams();
    const [context, setContext] = useState(sessionStorage.getItem(ContextStoreName) || '');

    useEffect(() => {
        const queryContext = searchParams.get('context');

        if (queryContext) {
            sessionStorage.setItem(ContextStoreName, queryContext.toString());
            setContext(queryContext.toString());
            // Keeps app in sync with BC (e.g. heatbeat, user logout, etc)
            bigCommerceSDK(queryContext);
        }
    }, [searchParams]);
    
    return (
       <SessionContext.Provider value={{ context }}>
           {children}
       </SessionContext.Provider>
    );
};

export const useSession = () => useContext(SessionContext);

export default SessionProvider;
