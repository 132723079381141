import useSWR, { KeyedMutator } from 'swr';
import { useSession } from 'context/session';
import { fetcher } from './base';

export function useDieItems() {
    const { context } = useSession();
    const params = new URLSearchParams({ context }).toString();
    // Use an array to send multiple arguments to fetcher
    const { data, error, mutate: mutateList }: { data?: string[], error?: any, mutate: KeyedMutator<any> } = useSWR(context ? ['/api/dieItems', params] : null, fetcher);

    return {
        list: data,
        isLoading: !data && !error,
        error,
        mutateList,
    };
}
