import { Box, Button, Flex, FormControlLabel, FormGroup, InlineMessage, Input, Panel, ProgressCircle, Select, Form as StyledForm, Worksheet, WorksheetColumn } from '@bigcommerce/big-design';
import { AddCircleOutlineIcon, RemoveCircleOutlineIcon, VisibilityIcon, VisibilityOffIcon } from '@bigcommerce/big-design-icons';
import { ChangeEvent, FormEvent, useState } from 'react';
import styled from 'styled-components';
import { StringKeyValue, PriceMatrix, PriceMatrixEntry } from 'types';

interface FormProps {
    initialFormData: PriceMatrix;
    onCancel(): void;
    onSubmit(form: PriceMatrix): Promise<Response>;
    onToggleActive(): Promise<void>;
    customerGroupList?: any[];
    dieItemList?: string[];
}

interface PricingRow extends Record<string, number> {
    minimumPalletQuantity: number,
    cartonQuantity: number,
    rowIndex?: number,
}

const ControlLoading = ({name}: {name: string}) => (
    <div>
        <FormControlLabel>{name}</FormControlLabel>
        <Flex justifyContent={'center'}>
            <ProgressCircle />
        </Flex>
    </div>
);

const StyledBox = styled(Box)`
    width: 50%;
    margin-bottom: 1rem;    
    :last-child {
        margin-bottom: 0;
    }
`;
const StyledFormGroup = ({children}) => {
    return (
        <StyledBox>
            <FormGroup>
                {children}
            </FormGroup>
        </StyledBox>
    );
}

const FormErrors = {
    nationalAccountName: 'Account Name is required',
    dieItem: 'Die Item is required',
};

const priceEntryFieldRegex = /sku(\d+)Price/;
const pluralize = (word: string, count: number) => Math.abs(count) > 1 ? `${word}s`: word;

// Group by palletQuantity to form rows
const groupEntriesByQuantity = (entries?: PriceMatrixEntry[]) => Object.values(Object.groupBy(entries || [], ({ minimumPalletQuantity }) => minimumPalletQuantity));

const Form = ({ initialFormData, onCancel, onSubmit, onToggleActive, customerGroupList, dieItemList }: FormProps) => {
    const [formData, setFormData] = useState<PriceMatrix>(initialFormData);
    const [errors, setErrors] = useState<StringKeyValue>({});
    const [isLoading, setIsLoading] = useState(false);

    const { additionalItemCharge, isActive, maxSkuCount, entries, cartonQuantity, nationalAccountName, dieItem } = formData;

    const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name: formName, value: stringValue } = event?.target;
        const value = (event.target.type === 'number') ? Number(stringValue) : stringValue;

        setFormData(prevForm => ({ ...prevForm, [formName]: value }));

        // Add error if it exists in FormErrors and the input is empty, otherwise remove from errors
        !value && FormErrors[formName]
            ? setErrors(prevErrors => ({ ...prevErrors, [formName]: FormErrors[formName] }))
            : setErrors(({ [formName]: removed, ...prevErrors }) => ({ ...prevErrors }));
    };

    const handleSelectChange = (fieldName: string, value: string) => setFormData(prevForm => ({ ...prevForm, [fieldName]: value }));

    const handleRowDelete = (rowIndex?: number | "") => {
        setFormData(prevForm => {
            const rows = groupEntriesByQuantity(prevForm.entries);
            const rowEntries = rows[rowIndex];
            const filteredEntries = prevForm.entries.filter(entry => entry.minimumPalletQuantity !== rowEntries[0].minimumPalletQuantity);
            return { ...prevForm, entries: filteredEntries };
        });
    }

    const handleRowAdd = () => {
        setFormData(prevForm => {
            const newEntries = [];
            for (let i = 1; i <= prevForm.maxSkuCount; i++) {
                newEntries.push({
                    minimumPalletQuantity: null,
                    numberOfSkus: i,
                    price: null
                });
            }

            return { ...prevForm, entries: [...prevForm.entries, ...newEntries] };
        });
    }

    const handleRowChange = (updatedRows: PricingRow[]) => {
        setFormData(prevForm => { 
            const existingRows = groupEntriesByQuantity(prevForm.entries);

            for (const updatedRow of updatedRows) {
                const { minimumPalletQuantity, rowIndex } = updatedRow;

                // Convert the column properties for the row into an array of entries
                //  and either match to existing entries (update) or add new ones
                const updatedEntries: PriceMatrixEntry[] = Object.entries(updatedRow)
                    .filter(([propertyName, _]) => propertyName.match(priceEntryFieldRegex))
                    .map(([key, value]) => ({
                        minimumPalletQuantity,
                        numberOfSkus: Number(priceEntryFieldRegex.exec(key)[1]),
                        price: value
                    }));

                const existingRow = existingRows.length > rowIndex && existingRows[rowIndex];

                if (!existingRow) {
                    existingRows.push(updatedEntries);
                } else {
                    for (const updatedEntry of updatedEntries) {
                        // Match on number of SKUs (column)
                        const existingEntry = existingRow.find(({ numberOfSkus }) => numberOfSkus === updatedEntry.numberOfSkus);

                        if (!existingEntry) {
                            existingRow.push(updatedEntry);
                        } else {
                            existingEntry.price = updatedEntry.price;
                            existingEntry.minimumPalletQuantity = updatedEntry.minimumPalletQuantity;
                        }
                    }
                }
            }

            return { ...prevForm, entries: existingRows.flat() };
        });
    }

    const handleSubmit = async (event: FormEvent<EventTarget>) => {
        event.preventDefault();
        setIsLoading(true);

        // If there are errors, do not submit the form
        const hasErrors = Object.keys(errors).length > 0;
        if (hasErrors) return;

        await onSubmit(formData);

        setIsLoading(false);
        onCancel();
    };

    const buildColumns = () => {
        const columns: Array<WorksheetColumn<PricingRow>> = [
            {
                hash: 'minimumPalletQuantity',
                header: '# of Pallets',
                type: 'number',
                validation: (value) => value && value > 0
            },
            {
                hash: 'cartonQuantity',
                header: 'Carton Qty',
                type: 'number',
                disabled: true,
            }
        ];
    
        for (var i = 1; i <= maxSkuCount ?? 1; i++) {
            columns.push({
                hash: `sku${i}Price`,
                header: `${i} ${pluralize('SKU', i)}`,
                type: 'number',
                formatting: (value?: number) => value ? `$${value.toFixed(2)}` : null,
                notation: (value?: number) => value ? { color: 'secondary', description: `$${value * cartonQuantity / 1000} per pallet` } : null,
                validation: (value) => !value || value > 0
            })
        }
    
        columns.push({
            hash: 'rowIndex',
            header: null,
            type: 'number',
            formatting: (value?) => value === 0
                ? null
                : (
                <Button 
                    actionType='destructive'
                    type='button'
                    variant='subtle'
                    iconRight={<RemoveCircleOutlineIcon />}
                    onClick={() => handleRowDelete(value)}
                >
                    Delete Row
                </Button>
            ),
            disabled: true,
            width: 180
        })

        return columns;
    }

    // Iterate through group properties (rows) to "flatten" individual the entries into properties on a single row object
    //  eg. { "1": [ {"minimumPalletQuantity": 1, "numberOfSkus": 1, "price": 9.99}, ... ], "2": ... }
    //    becomes
    //      [ {"minimumPalletQuantity": 1, "cartonQuantity": 5, "sku1Price": 9.99, "sku2Price": ...}, ... ]
    const rows: Array<PricingRow> = groupEntriesByQuantity(entries).map((group, index) => {
        const { minimumPalletQuantity } = group[0];
        const row = {
            minimumPalletQuantity: minimumPalletQuantity,
            quantityId: minimumPalletQuantity,
            rowIndex: index,
            cartonQuantity: cartonQuantity ? cartonQuantity * minimumPalletQuantity : null
        };

        // Iterate through entries of group and assign t
        for (const { numberOfSkus, price } of group) {
            row[`sku${numberOfSkus}Price`] = price;
        }

        return row;
    });

    const canSave = !rows.some(row => !row.minimumPalletQuantity);

    return (
        <StyledForm fullWidth onSubmit={handleSubmit}>
            <Panel
                header="Quote Information"
                action={{
                    variant: 'secondary',
                    text: (isActive ? 'Deactivate' : 'Activate'),
                    actionType: (isActive ? 'destructive' : 'normal'),
                    type: 'button',
                    iconLeft: (isActive ? <VisibilityOffIcon /> : <VisibilityIcon />),
                    onClick: async () => await onToggleActive(),
                    disabled: isLoading
                }}
            >
                <StyledFormGroup>
                    {
                        (!customerGroupList)
                            ? <ControlLoading name='National Account' />
                            : <Select
                                label="National Account"
                                name="nationalAccountName"
                                options={customerGroupList.map(({name}) => ({ value: name, content: name }))}
                                required
                                value={nationalAccountName}
                                onOptionChange={(value) => handleSelectChange('nationalAccountName', value)}
                            />
                    }
                    {
                        (!dieItemList)
                            ? <ControlLoading name='Die Item' />
                            : <Select
                                label="Die Item"
                                name="dieItem"
                                options={dieItemList.map((name) => ({ value: name, content: name }))}
                                required
                                value={dieItem}
                                onOptionChange={(value) => handleSelectChange('dieItem', value)}
                            />
                    }
                </StyledFormGroup>
                <StyledFormGroup>
                    <Input
                        error={errors?.name}
                        label="Carton Qty / Pallet"
                        name="cartonQuantity"
                        required
                        type='number'
                        defaultValue={cartonQuantity}
                        min={1}
                        onBlur={handleChange}
                    />
                    <Input
                        error={errors?.name}
                        label="Max # of SKUs"
                        name="maxSkuCount"
                        required
                        type='number'
                        min={1}
                        defaultValue={maxSkuCount}
                        onBlur={handleChange}
                    />
                </StyledFormGroup>
                <StyledFormGroup>
                    <Input
                        error={errors?.name}
                        label="Additional Item Charge"
                        name="additionalItemCharge"
                        required
                        type='number'
                        step='any'
                        min={0}
                        defaultValue={additionalItemCharge.toFixed(2)}
                        onBlur={handleChange}
                    />
                </StyledFormGroup>
            </Panel>
            <Panel>
                <Flex justifyContent='center'>
                <InlineMessage
                    marginBottom='medium'
                    marginHorizontal='xxxLarge'
                    messages={[{ text: 'USD Pricing (per thousand)' }]}
                    type='info'
                />
                </Flex>
                <Worksheet
                    columns={buildColumns()}
                    items={rows}
                    onChange={handleRowChange}
                />
                <Flex>
                    <Button
                        marginLeft={"xLarge"}
                        marginTop={"small"}
                        type="button"
                        variant="subtle"
                        onClick={handleRowAdd}
                        iconRight={<AddCircleOutlineIcon />}
                        disabled={rows.some(row => !row.minimumPalletQuantity)}
                    >
                        Add Row
                    </Button>
                </Flex>
            </Panel>
            <Flex justifyContent="flex-end">
                <Button
                    marginRight="medium"
                    type="button"
                    variant="subtle"
                    onClick={onCancel}
                >
                    Cancel
                </Button>
                <Button type="submit" disabled={!canSave} isLoading={isLoading}>Save</Button>
            </Flex>
        </StyledForm>
    );
};

export default Form;
