import { Box, Button, H1, HR, Text } from '@bigcommerce/big-design';
import { ArrowBackIcon } from '@bigcommerce/big-design-icons';
import { useNavigate, useMatches } from 'react-router-dom';
import { ListHook } from 'types';
import Loading from './loading';

const InnerHeader = ({ title }: { title: string }) => {
    const navigate = useNavigate();
    const matches = useMatches();
    const innerHeaderMatch = matches.find(match => typeof match.params.id !== 'undefined' && typeof match.handle !== 'undefined');
    const { params: { id }, handle } = innerHeaderMatch;
    const { listHook } = handle as { listHook: ListHook };
    const { list = [], isLoading } = listHook();
    const { name } = list.find(item => item.id === Number(id)) ?? {};

    const label = isLoading
        ? <Loading />
        : id === '0' || id === 'create'
            ? <H1>Add New</H1>
            : <H1>{name || id}</H1>;

    return (
        <Box marginBottom="xxLarge">
            <Button iconLeft={<ArrowBackIcon color="secondary50" />} variant="subtle" marginBottom='medium' onClick={() => navigate('/..', { relative: "path" })}>
                <Text bold color="secondary50">{title}</Text>
            </Button>
            {label}
            <HR color="secondary30" />
        </Box>
    );
};

export default InnerHeader;
