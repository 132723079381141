import { Box, TabItem, Tabs } from '@bigcommerce/big-design';
import { useNavigate, useMatches } from 'react-router-dom';
import InnerHeader from './innerHeader';

export const TabNames = {
    HOME: 'Home',
    PRICEMATRICES: 'PriceMatrices',
    WEBHOOKS: 'Webhooks',
};

export const TabRoutes = {
    [TabNames.HOME]: '/',
    [TabNames.PRICEMATRICES]: '/priceMatrices',
    [TabNames.WEBHOOKS]: '/webhooks',
};

interface Tab extends TabItem {
    hidden?: boolean
}

const AllTabs: Tab[] = [
    { ariaControls: TabNames.PRICEMATRICES, id: TabNames.PRICEMATRICES, title: 'Price Matrix Records' },
    { ariaControls: TabNames.WEBHOOKS, id: TabNames.WEBHOOKS, title: 'Webhooks' },
];

const Header = () => {
    const navigate = useNavigate();
    const matches = useMatches();
    const { id: activeTabId, title: activeTabTitle } = ([...AllTabs].reverse().find(({id}) => matches.some(({pathname}) => pathname.includes(TabRoutes[id]))) || AllTabs[0]);
    const innerHeaderMatch = matches.find(match => typeof match.params.id !== 'undefined' && typeof match.handle !== 'undefined');

    if (innerHeaderMatch) return <InnerHeader title={activeTabTitle} />;
    
    const handleTabClick = (tabId: string) => navigate(TabRoutes[tabId], { });

    return (
        <Box marginBottom='xxLarge'>
            <Tabs
                activeTab={activeTabId}
                items={AllTabs.filter(({ id, hidden }) => !hidden || activeTabId === id)}
                onTabClick={handleTabClick}
            />
        </Box>
    );
};

export default Header;
