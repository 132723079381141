import { useEffect } from "react";
import { RouteObject, useLocation, useNavigate } from "react-router-dom";

import App from "App";
import { PriceMatrixRoutes, WebhookRoutes } from 'pages';
import { TabNames, TabRoutes } from 'components/header';

const DefaultTab = TabNames.PRICEMATRICES;

const NavigateToDefault = () => {
    const { pathname, search } = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const defaultTabRoute = TabRoutes[DefaultTab];
        if (pathname === '/') navigate(`${defaultTabRoute}${search}`);
    })

    return null;
}

const DefaultRoute = {
    path: '/',
    Component: NavigateToDefault
 };

const routes : RouteObject[] = [
    {
        Component: App,
        children: [
            DefaultRoute,
            PriceMatrixRoutes,
            WebhookRoutes,
        ]
    }
];

export default routes;
