import PriceMatrixList from './priceMatrixList';
import PriceMatrixInfo from './priceMatrixInfo';
import { usePriceMatrixList } from 'hooks';

const routes = {
    path: 'priceMatrices/*',
    children: [
        { path: ':id', Component: PriceMatrixInfo, handle: { listHook: usePriceMatrixList }  },
        { index: true, Component: PriceMatrixList },
    ]
};

export default routes;
export { default as PriceMatrixList } from './priceMatrixList';
export { default as PriceMatrixInfo } from './priceMatrixInfo';
