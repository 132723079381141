import { Outlet } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { Box, GlobalStyles } from '@bigcommerce/big-design';
import Header from 'components/header';
import SessionProvider from 'context/session';

const App = () => {
  const { pathname } = useLocation();
  const isAppExtension = pathname.includes("AppExtension");
  
  return (
    <SessionProvider>
      <GlobalStyles />
      <Box marginHorizontal="xxxLarge" marginVertical="xxLarge">
          {!isAppExtension && <Header />}
          <Outlet />
      </Box>
    </SessionProvider>
  );
}

export default App;
