import useSWR, { KeyedMutator } from 'swr';
import { useSession } from 'context/session';
import { DataPage, TableQueryParamDefaults, TableQueryParams } from 'types';
import { fetcher } from './base';

export function useCustomerGroups(query?: TableQueryParams) {
    const { context } = useSession();
    const params = new URLSearchParams({ ...query, ...TableQueryParamDefaults, context }).toString();
    // Use an array to send multiple arguments to fetcher
    const { data, error, mutate: mutateList }: { data?: DataPage<any>, error?: any, mutate: KeyedMutator<any> } = useSWR(context ? ['/api/customerGroups', params] : null, fetcher);

    return {
        list: data?.data,
        pagingInfo: data?.pagingInfo,
        isLoading: !data && !error,
        error,
        mutateList,
    };
}

export function useAllCustomerGroups(query?: TableQueryParams) {
    const { context } = useSession();
    const params = new URLSearchParams({ ...query, ...TableQueryParamDefaults, context }).toString();
    // Use an array to send multiple arguments to fetcher
    const { data, error, mutate: mutateList }: { data?: any[], error?: any, mutate: KeyedMutator<any> } = useSWR(context ? ['/api/customerGroups/all', params] : null, fetcher);

    return {
        list: data,
        isLoading: !data && !error,
        error,
        mutateList,
    };
}

