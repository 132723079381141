import { useNavigate, useParams } from 'react-router-dom';

import ErrorMessage from 'components/error';
import { PriceMatrixForm } from 'components/forms';
import Loading from 'components/loading';
import { useAllCustomerGroups, useDieItems, usePriceMatrixList } from 'hooks';
import { useSession } from 'context/session';
import { PriceMatrix } from 'types';

const InitialMatrixInfo : PriceMatrix = {
    nationalAccountName: "",
    dieItem: "",
    dieDescription: "",
    maxSkuCount: 1,
    additionalItemCharge: 0.00,
    entries: [{
        minimumPalletQuantity: 1,
        numberOfSkus: 1
    }]
};

const PriceMatrixInfo = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const isCreate = id === 'create';
    const encodedContext = useSession()?.context;
    const { error, isLoading, list = [], mutateList } = usePriceMatrixList();
    const { list: customerGroupList } = useAllCustomerGroups();
    const { list: productList } = useDieItems();

    const priceMatrix = list.find(({ guid }) => guid === id) || { ...InitialMatrixInfo };

    const handleCancel = () => navigate('/..', { relative: "path" });

    const handleSubmit = async (data: PriceMatrix) : Promise<Response> => {
        const filteredList = list.filter(item => item.guid !== id);

        // Update local data immediately (reduce latency to user)
        mutateList([...filteredList, { ...data }], false);

        let response;

        try {
            response = await fetch(
                isCreate
                    ? `/api/priceMatrices?context=${encodedContext}`
                    : `/api/priceMatrices/${id}?context=${encodedContext}`,
                {
                    method: isCreate ? 'POST' : 'PUT',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(data),
                });
        } catch (error) {
            console.error(`Error ${(isCreate ? 'creating' : 'updating')} the Price Matrix: `, error);
        }
        
        // Refetch to validate local data
        mutateList();

        return response;
    };

    const handleToggleActive = async () => {
        priceMatrix.isActive = !priceMatrix.isActive;

        const filteredList = list.filter(item => item.guid !== id);

        // Update local data immediately (reduce latency to user)
        mutateList([...filteredList, priceMatrix], false);

        if (isCreate)
            return;

        await fetch(`/api/priceMatrices/${id}?context=${encodedContext}`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(priceMatrix),
        });
    }

    if (error) return <ErrorMessage error={error} />;
    if (isLoading) return <Loading />;

    return (
        <PriceMatrixForm
            initialFormData={priceMatrix}
            onCancel={handleCancel}
            onSubmit={handleSubmit}
            onToggleActive={handleToggleActive}
            customerGroupList={customerGroupList}
            dieItemList={productList}
        />
    );
};

export default PriceMatrixInfo;
