import { ChangeEvent, ReactElement, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Dropdown, Flex, Panel, Search, Table, TableSortDirection } from '@bigcommerce/big-design';
import { AddIcon, CheckIcon, CloseIcon, EditIcon, MoreHorizIcon, VisibilityIcon, VisibilityOffIcon } from '@bigcommerce/big-design-icons';

import ErrorMessage from 'components/error';
import Loading from 'components/loading';
import { usePriceMatrixList } from 'hooks';
import { useSession } from 'context/session';
import { TabNames } from 'components/header';

const DefaultTableState = {
    itemsPerPage: 10,
    currentPage: 1,
    columnHash: '',
    direction: 'ASC',
    searchValue: '',
}

const itemsPerPageOptions = [10, 20, 50, 100];

const PriceMatrixList = () => {
    const tableState = JSON.parse(window.sessionStorage.getItem('PriceMatrixTableState')) || DefaultTableState;
    const [itemsPerPage, setItemsPerPage] = useState(Number(tableState.itemsPerPage));
    const [currentPage, setCurrentPage] = useState(Number(tableState.currentPage));
    const [columnHash, setColumnHash] = useState(tableState.columnHash);
    const [direction, setDirection] = useState<TableSortDirection>(tableState.direction);
    const [searchValue, setSearchValue] = useState(tableState.searchValue);
    const [searchText, setSearchText] = useState('');

    useEffect(
        () => {
            window.sessionStorage.setItem('PriceMatrixTableState', JSON.stringify({
                itemsPerPage,
                currentPage,
                columnHash,
                direction
            }));
        },
        [itemsPerPage, currentPage, columnHash, direction, searchValue]
    )

    let searchTimeout: NodeJS.Timeout;

    
    const { error, isLoading, list = [], pagingInfo, mutateList } = usePriceMatrixList({
      ...(searchValue && { keyword: searchValue }),
      page: String(currentPage),
      limit: String(itemsPerPage),
      ...(columnHash && { sort: columnHash }),
      ...(columnHash && { direction: direction.toLowerCase() }),
    });
    const encodedContext = useSession()?.context;
    const navigate = useNavigate();

    const onItemsPerPageChange = (newRange: number) => {
        setCurrentPage(1);
        setItemsPerPage(newRange);
    };

    const onSort = (newColumnHash: string, newDirection: TableSortDirection) => {
        setColumnHash(newColumnHash === 'stock' ? 'inventory_level' : newColumnHash);
        setDirection(newDirection);
    };

    const onSearchChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const {value} = event?.target;
        
        setSearchText(value);

        if (value === '') {
            setSearchValue(value);
        } else {
            clearTimeout(searchTimeout);
            searchTimeout = setTimeout(onSearchSubmit, 500);
        }
    }

    const onSearchSubmit = () => {
        setSearchValue(searchText);
    }

    const renderName = (guid: string, name: string): ReactElement => (
        <Link to={guid}>{name}</Link>
    );

    const renderActive = (isActive: boolean): ReactElement => (
        isActive ? <CheckIcon /> : <CloseIcon />
    );

    const renderAddAction = () => (
        <Button
            iconLeft={ <AddIcon /> }
            onClick={() => navigate('create')}
        >
            Create Pricing Matrix
        </Button>
    );

    const renderSearchBar = () => (
        <Search
            value={searchText}
            onChange={onSearchChange}
            onSubmit={onSearchSubmit}
        />
    );

    const renderHeaderActions = () => (
        <Flex flexGap={"0.75rem"}>
            {renderSearchBar()}
            {renderAddAction()}
        </Flex>
    );

    const renderItemActions = (guid: string, isActive?: boolean): ReactElement => (
        <Dropdown
            items={[
                { content: 'Edit', onItemClick: () => navigate(guid), hash: 'edit', icon: <EditIcon /> },
                { content: (isActive ? 'Deactivate' : 'Activate'), onItemClick: () => onToggleActivate(guid), hash: 'deactivate', icon: (isActive ? <VisibilityOffIcon /> : <VisibilityIcon />) },
            ]}
            toggle={<Button iconOnly={<MoreHorizIcon color="secondary60" />} variant="subtle" />}
        />
    );

    const getRangeLabel = (start: number, end: number, totalItems: number): string => (
         `${start} - ${end} of ${!totalItems || totalItems === 999999 ? 'Unknown' : totalItems}`
    );

    const onToggleActivate = async (guid: string) => {
        const filteredList = list.filter(item => item.guid !== guid);
        const matrix = list.find(item => item.guid === guid);
        matrix.isActive = !matrix.isActive;

        // Update local data immediately (reduce latency to user)
        mutateList([...filteredList, matrix], false);

        await fetch(`/api/priceMatrices/${guid}?context=${encodedContext}`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(matrix),
        });

        // Refetch to validate local data
        mutateList();
    }

    if (error) return <ErrorMessage error={error} />;
    if (isLoading) return <Loading />;

    return (
        <Panel id={TabNames.PRICEMATRICES}>
            <Table
                actions={renderHeaderActions()}
                columns={[
                    { header: 'National Account', hash: 'account', render: ({ guid, nationalAccountName }) => renderName(guid, nationalAccountName), isSortable: true },
                    { header: 'Die Item', hash: 'item', render: ({ dieItem }) => dieItem, isSortable: true },
                    { header: 'Active', hash: 'active', render: ({ isActive }) => renderActive(isActive) },
                    { header: 'Action', hideHeader: true, hash: 'id', render: ({ guid, isActive }) => renderItemActions(guid, isActive) },
                ]}
                items={list}
                pagination={{
                    currentPage,
                    totalItems: pagingInfo?.totalResults || 999999,
                    onPageChange: setCurrentPage,
                    itemsPerPageOptions,
                    onItemsPerPageChange,
                    itemsPerPage,
                    getRangeLabel
                }}
                sortable={{
                  columnHash,
                  direction,
                  onSort,
                }}
                stickyHeader
            />
        </Panel>
    );
};

export default PriceMatrixList;
