import { TableSortDirection } from "@bigcommerce/big-design";
import { QueryParams } from "types";

export interface ContextValues {
  context: string;
  setContext: (key: string) => void;
}

export interface StringKeyValue {
  [key: string]: string;
}

export interface ListItem {
  id: number;
  name: string;
}

export interface ListHook {
  (): {
    list: ListItem[];
    isLoading: Boolean;
    error: any;
  }
}

export interface DataPage<Data> {
  data: Data[];
  pagingInfo: {
    pageIndex: number;
    pageSize: number;
    totalPages?: number;
    totalResults?: number;
  };
}

export interface TableQueryParams extends QueryParams {
  page: string;
  limit: string;
  sort?: string;
  direction?: string;
  searchValue?: string;
}

export const TableQueryParamDefaults : TableQueryParams = {
  page: String(1),
  limit: String(10)
}
