import { Box, Button, H3 } from '@bigcommerce/big-design';
import { AddCircleOutlineIcon, CheckIcon, CloseIcon, RemoveCircleOutlineIcon, RestoreIcon, WarningIcon } from '@bigcommerce/big-design-icons';
import { decodeJwt } from 'jose';
import ErrorMessage from 'components/error';
import Loading from 'components/loading';
import { useSession } from 'context/session';
import { KeyedMutator } from 'swr';

interface FormProps {
    webhookScope: string,
    webhookName: string,
    webhookController: string,
    list: any[],
    isLoading: boolean,
    error: any,
    mutateList: KeyedMutator<any>,
}

const WebhookStatus = ({ webhookScope, webhookName, webhookController, list, isLoading, error, mutateList }: FormProps) => {
    const encodedContext = useSession()?.context;
    const webhook = list?.find(hook => hook.scope === webhookScope);
    const sessionToken = decodeJwt(encodedContext), {  aud: storeHash } = sessionToken;

    if (isLoading) return <Loading />;
    if (error) return <ErrorMessage error={error} />;

    const handleCreateWebhook = async () => {
        await fetch(`/api/${webhookController}?context=${encodedContext}`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(webhook),
        });

        mutateList(list);
    }

    const handleUpdateWebhook = async () => {
        webhook.headers = { storeHash };
        webhook.is_active = true;
        
        await fetch(`/api/${webhookController}/${webhook.id}?context=${encodedContext}`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(webhook),
        });

        mutateList(list);
    }

    const handleDeleteWebhook = async () => {
        await fetch(`/api/${webhookController}/${webhook.id}?context=${encodedContext}`, {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' }
        });

        mutateList(list);
    }

    if (!webhook) {
        return (
            <>
                <H3>{webhookName} Webhook not found. <CloseIcon color="danger" size="xxLarge" /></H3>
                <Button type="button" onClick={handleCreateWebhook} iconLeft={<AddCircleOutlineIcon />}>Create Webhook</Button>
            </>
        );
    }
    
    const { is_active, headers = {} } = webhook;

    if (!is_active || headers.storeHash !== storeHash) {
        return (
            <>
                <H3>{webhookName} Webhook inactive or misconfigured. <WarningIcon color="warning" size="xxLarge" /></H3>
                <Button type="button" onClick={handleUpdateWebhook} iconLeft={<RestoreIcon />}>Fix Webhook</Button>
            </>
        );
    }

    return (
        <>
            <H3>{webhookName} Webhook is active. <CheckIcon color="success" size="xxLarge" /></H3>
            <Box>
                <Button type="button" onClick={handleDeleteWebhook} actionType="destructive" iconLeft={<RemoveCircleOutlineIcon />}>Remove Webhook</Button>
            </Box>
        </>
    );
};

export default WebhookStatus;
