import useSWR, { KeyedMutator } from 'swr';
import { useSession } from 'context/session';
import { DataPage, PriceMatrix, TableQueryParamDefaults, TableQueryParams } from 'types';
import { fetcher } from './base';
import { TableSortDirection } from '@bigcommerce/big-design';

const InitialMatrixInfo : PriceMatrix = {
    nationalAccountName: "",
    dieItem: "",
    dieDescription: "",
    maxSkuCount: 1,
    additionalItemCharge: 0.00,
    entries: [{
        minimumPalletQuantity: 1,
        numberOfSkus: 1
    }]
};

const DefaultTableState: {
    itemsPerPage: number,
    currentPage: number,
    columnHash: '',
    direction: TableSortDirection,
    searchValue: '',
} = {
    itemsPerPage: 10,
    currentPage: 1,
    columnHash: '',
    direction: 'ASC',
    searchValue: '',
}

export function usePriceMatrixList(query?: TableQueryParams) {
    const { context } = useSession();
    const storedTableParams = useStoredPriceMatrixTableParams();
    const params = new URLSearchParams({ ...TableQueryParamDefaults, ...storedTableParams, ...query, context }).toString();
    // Use an array to send multiple arguments to fetcher
    const { data, error, mutate: mutateList } : { data?: DataPage<PriceMatrix>, error?: any, mutate: KeyedMutator<any> } = useSWR(context ? ['/api/priceMatrices', params] : null, fetcher);

    return {
        list: data?.data,
        pagingInfo: data?.pagingInfo,
        isLoading: !data && !error,
        error,
        mutateList,
    };
}

export function usePriceMatrixInfo(matrixId: string, list: PriceMatrix[]) {
    const { context } = useSession();
    const isCreate = matrixId === 'create';
    const matrix = isCreate ? InitialMatrixInfo : list.find(item => item.guid === matrixId);
    const params = new URLSearchParams({ context }).toString();

    // Conditionally fetch cutomer if it doesn't exist in the list (e.g. deep linking)
    const { data, error }: { data?: PriceMatrix, error?: any } = useSWR(!matrix && !isCreate && context ? [`/api/priceMatrices/${matrixId}`, params] : null, fetcher);

    return {
        priceMatrix: matrix ?? data,
        isLoading: matrix ? false : (!data && !error),
        error,
    };
}

export function useStoredPriceMatrixTableParams() : TableQueryParams {
    const tableState = JSON.parse(window.sessionStorage.getItem('PriceMatrixTableState')) || DefaultTableState;
    const { columnHash, currentPage, direction, itemsPerPage, searchValue } = tableState;

    return {
        ...(searchValue && { keyword: searchValue }),
        page: String(currentPage),
        limit: String(itemsPerPage),
        ...(columnHash && { sort: columnHash }),
        ...(columnHash && { direction: direction.toLowerCase() }),
    };
}
